exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cancellation-js": () => import("./../../../src/pages/cancellation.js" /* webpackChunkName: "component---src-pages-cancellation-js" */),
  "component---src-pages-complaints-js": () => import("./../../../src/pages/complaints.js" /* webpackChunkName: "component---src-pages-complaints-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internal-style-guide-js": () => import("./../../../src/pages/internal-style-guide.js" /* webpackChunkName: "component---src-pages-internal-style-guide-js" */),
  "component---src-pages-opt-out-js": () => import("./../../../src/pages/opt-out.js" /* webpackChunkName: "component---src-pages-opt-out-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-under-construction-js": () => import("./../../../src/pages/under-construction.js" /* webpackChunkName: "component---src-pages-under-construction-js" */),
  "component---src-templates-guide-template-js": () => import("./../../../src/templates/Guide-template.js" /* webpackChunkName: "component---src-templates-guide-template-js" */),
  "component---src-templates-policy-template-js": () => import("./../../../src/templates/Policy-template.js" /* webpackChunkName: "component---src-templates-policy-template-js" */)
}

